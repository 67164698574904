
import IndexContent from '../components/content/IndexContent.vue';
import Download from '../components/download/Download.vue';
import HistoryVersion from '../components/download/HistoryVersion.vue';

const routes = [
	{
		path: '/', component: IndexContent
	},
	{
		path: '/Download', component: Download
	},
	{
		path: '/HistoryVersion', component: HistoryVersion
	}

]


export {
	routes
}