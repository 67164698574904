import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import VueRouter from 'vue-router'
import VueAMap from 'vue-amap'
import i18n from './util/vueI18n'
import axios from 'axios'

// 路由三方库
import {routes} from './core/router.js'
import 'swiper/swiper-bundle.css'


Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper)

Vue.use(VueAMap)
Vue.prototype.axios = axios;

Vue.config.productionTip = false;


Vue.use(VueRouter)
const router = new VueRouter({
    mode: "history",
	  routes
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

//VueRouter(App,router,'#app')