
import Vue from 'vue';
import Vuex from 'vuex';


/*
 * 数据格式如下:
 * 用户信息
 * let userInfo = {
 * 	'username' : res.username,
 * 	'phone' : res.mobile,
 * 	'avatar' : res.headImgUrl,
 * 	'role': res.nickname,
 * 	'userId': res.id,
 * 	'permissions': permissions
 *{
 * 	'name': item.name,
 * 	'roleName': ROLE_NAMES[index],
 * 	'roleType': ROLES[index],
 * 	'id': item.id
 * }
 * };
 */


Vue.use(Vuex);

const store = new Vuex.Store({
  state: {

    //当前语言
    currentLanguage: '',
    
    //当前域名
    currentHost: '',
  },

  getters: {
    // this.$store.getters['getCurrentLanguage']
    getCurrentLanguage: state => state.currentLanguage,

    getCurrentHost: state => state.currentHost
  },

  mutations: {

    // this.$store.commit('setCurrentLanguage', {})
    setCurrentLanguage: (state, language) => {
      state.currentLanguage = language;
      //console.log("setCurrentLanguage:" + JSON.stringify(state.currentLanguage))
    },

    setCurrentHost: (state, host) => {
      state.currentHost = host
    }
  },

  modules: {

  },
});

export default store;
