<template>
	<div class="content">
		<div class="common">
			<div class="download-portal">
				<div class="portal-sort" v-if="curHost == 'shadow'">
					<div class="s-div m-right20">
						<p class="s-title">{{ $t('shadow.stepOne') }}</p>
						<ul class="flex flex-row">
							<li class="download-item" v-if="curHost == 'shadow'">
								<div class="icon-img">
									<img class="" src="../../assets/images/ai-img-w.png" alt="" />
								</div>
								<div class="download-text">
									<h3>{{ $t('shadow.titleWindow') }}</h3>
									<p class="bit-link">
										<a v-if="curHost == 'shadow'" href="https://shadowai-objects.oss-cn-shenzhen.aliyuncs.com/desktop_client/win32/shadow-ai_win32_x64.exe" class="download-btn" target="_blank">
											<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
											{{$t('window.linkExtend')}}
										</a>
									</p>
								</div>
								<div class="version-item">
									<p class="version-msg">{{$t('window.version')}}: 1.5.0</p>
									<p class="version-msg">{{$t('window.updateTime')}}: 2023-10-26</p>
									<p class="version-msg">{{$t('window.compatible')}}: Windows8/10/11</p>
								</div>
							</li>
							<li class="download-item" v-if="curHost == 'shadow'" style="margin-right: 0;">
								<div class="icon-img">
									<img class="" src="../../assets/images/ai-img-apple.png" alt="" />
								</div> 
								<div class="download-text">
									<h3>{{ $t('shadow.titleMac') }}</h3>
									<p class="bit-link">
										<a href="https://shadowai-objects.oss-cn-shenzhen.aliyuncs.com/desktop_client/darwin/shadow-ai_darwin_universal.dmg" class="download-btn" target="_blank">
											<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/>
											{{$t('mac.linkExtend')}}
										</a>
									</p>
								</div>
								<div class="version-item">
									<p class="version-msg">{{$t('mac.version')}}: 1.5.0</p>
									<!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
									<p class="version-msg">{{$t('mac.updateTime')}}: 2023-10-26 </p>
									<p class="version-msg">{{$t('mac.compatible')}}: Mac OS</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="s-div m-right20">
						<p class="s-title">{{ $t('shadow.stepTwo') }}</p>
						<ul class="flex flex-row">
							<li class="download-item" v-if="curHost == 'shadow'">
								<div class="icon-img">
									<img class="" src="../../assets/images/ai-img-w.png" alt="" />
								</div>
								<div class="download-text">
									<h3>{{$t('shadow.titleShadow')}}</h3>
									<p class="bit-link">
										<a :href="newWindow.url" class="download-btn">
											<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
											{{$t('window.linkExtend')}}
										</a>
										<!-- <a :href="newWindowBeta.url" class="download-btn-small">
											<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
											{{$t('window.linkTwo')}}
										</a> -->
									</p>
								</div>
								<div class="version-item">
									<p class="version-msg">{{$t('window.version')}}: {{newWindow.version}}</p>
									<p class="version-msg">{{$t('window.updateTime')}}: {{newWindow.createTime | formatDate}}</p>
									<p class="version-msg">{{$t('window.compatible')}}: Windows7/8/10/11</p>
								</div>
							</li>
							<li class="download-item" v-if="curHost == 'shadow'" style="margin-right: 0;">
								<div class="icon-img">
									<img class="" src="../../assets/images/ai-img-apple.png" alt="" />
								</div> 
								<div class="download-text">
									<h3>{{$t('shadow.titleShadow')}}</h3>
									<p class="bit-link">
										<a :href="newMac.url" v-if="['www','shadow', 'imouse'].includes(curHost)" class="download-btn-small m-right10">
											<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
											{{$t('mac.linkOne')}}
										</a>
										<a href="https://apps.apple.com/cn/app/卓尧ai/id6461728650?mt=12" v-if="curHost == 'shadow'" class="download-btn-small" target="_blank">
											{{$t('mac.linkTwo')}}
										</a>
									</p>
								</div>
								<div class="version-item">
									<p class="version-msg">{{$t('mac.version')}}: {{newMac.version}}</p>
									<!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
									<p class="version-msg">{{$t('mac.updateTime')}}: {{newMac.createTime | formatDate}}</p>
									<p class="version-msg">{{$t('mac.compatible')}}: Mac 10.15以上</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
				
				<ul class="portal-sort" v-if="curHost != 'shadow'">
					<li class="download-item">
						<div class="icon-img">
                            <img class="" src="../../assets/images/ai-img-w.png" alt="" />
						</div>
						<div class="download-text">
							<h3>{{$t('window.title')}}</h3>
							<p class="bit-link">
								<a :href="newWindow.url" class="download-btn">
									<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
									{{$t('window.linkOne')}}
								</a>
								<!-- <a :href="newWindowBeta.url" class="download-btn-small">
									<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
									{{$t('window.linkTwo')}}
								</a> -->
							</p>
						</div>
						<div class="version-item">
							<p class="version-msg">{{$t('window.version')}}: {{newWindow.version}}</p>
							<p class="version-msg">{{$t('window.updateTime')}}: {{newWindow.createTime | formatDate}}</p>
							<p class="version-msg">{{$t('window.compatible')}}: Windows7/8/10/11</p>
						</div>
					</li>
					<li class="download-item">
						<div class="icon-img">
                            <img class="" src="../../assets/images/ai-img-apple.png" alt="" />
						</div> 
						<div class="download-text">
							<h3>{{$t('mac.title')}}</h3>
							<p class="bit-link">
								<a :href="newMac.url" v-if="['www','shadow', 'imouse', 'yundesk'].includes(curHost)" class="download-btn-small m-right10">
									<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
									{{$t('mac.linkOne')}}
								</a>
								<a href="https://apps.apple.com/cn/app/ai-office/id1480139966?mt=12" v-if="curHost == 'www' || curHost == 'imouse'" class="download-btn-small" target="_blank">
									{{$t('mac.linkTwo')}}
								</a>
								<a href="https://apps.apple.com/cn/app/卓尧ai/id6461728650?mt=12" v-if="curHost == 'shadow'" class="download-btn-small" target="_blank">
									{{$t('mac.linkTwo')}}
								</a>
								<a class="download-btn" v-if="['aiodiy', 'dido', 'thinkplus'].includes(curHost)"  :href="newMac.url">
									<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/>
									{{$t('mac.linkOne')}}
								</a>
							</p>
						</div>
						<div class="version-item">
							<p class="version-msg">{{$t('mac.version')}}: {{newMac.version}}</p>
							<!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
							<p class="version-msg">{{$t('mac.updateTime')}}: {{newMac.createTime | formatDate}}</p>
							<p class="version-msg">{{$t('mac.compatible')}}: Mac 10.15以上</p>
						</div>
					</li>
					<!-- 云电脑的 yundesk -->
					<li class="download-item" v-if="curHost == 'yundesk'">
						<div class="icon-img">
                            <img class="" src="../../assets/images/ai-img-y.png" alt="" />
						</div> 
						<div class="download-text">
							<h3>{{$t('windowYun.title')}}</h3>
							<p class="bit-link">
								<a class="download-btn"  href="https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/yun/YunOffice.exe">
									<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/>
									{{$t('windowYun.linkOne')}}
								</a>
							</p>
						</div>
						<div class="version-item">
							<p class="version-msg">{{$t('windowYun.version')}}: 5.1.8.5</p>
							<!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
							<p class="version-msg">{{$t('windowYun.updateTime')}}: 2023-12-07</p>
							<p class="version-msg">{{$t('windowYun.compatible')}}: Windows7/8/10/11</p>
						</div>
					</li>
					<li class="download-item" v-if="curHost == 'yundesk'">
						<div class="icon-img">
                            <img class="" src="../../assets/images/ai-img-ql.png" alt="" />
						</div> 
						<div class="download-text">
							<h3>{{$t('windowYun.title')}}</h3>
							<p class="bit-link">
								<a class="download-btn"  href="https://qjzn-app.oss-cn-hangzhou.aliyuncs.com/yun/YunOffice.exe">
									<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/>
									{{$t('windowYun.linkOne')}}
								</a>
							</p>
						</div>
						<div class="version-item">
							<p class="version-msg">{{$t('windowYun.version')}}: 5.1.8.5</p>
							<!-- <p class="version-msg">{{$t('mac.size')}}: 18.6M</p> -->
							<p class="version-msg">{{$t('windowYun.updateTime')}}: 2023-12-07</p>
							<p class="version-msg">{{$t('windowYun.compatible')}}: Linux</p>
						</div>
					</li>
					<li class="download-item" v-if="curHost == 'www'">
						<div class="icon-img img-over img-out" v-show="androidIcon" @mouseover="overAndroidCode">
                            <img class="" src="../../assets/images/ai-img-android.png" alt="" />
						</div>
						<div class="android-code img-over img-out" v-if="androidCode" @mouseout="outAndroidCode">
                            <!-- <img class="" src="../../assets/images/ai-img-acode.png" alt="" /> -->
							<vue-qr :logoSrc="logoIcon" :text="newAndroid.url" :size="110"></vue-qr>
						</div>
						<div class="download-text">
							<h3>{{$t('android.title')}}</h3>
							<p  class="bit-link">
								<a class="download-android m-right10" :href="newAndroid.url" target="_blank">
									<img src="../../assets/images/a-img-download1.png" width="20" style="vertical-align: middle;margin-right: 5px"/> 
									{{$t('android.linkOne')}}
								</a>
								<a class="download-android" target="_blank" href="https://play.google.com/store/apps/details?id=com.qjzn.earphone">{{$t('android.linkTwo')}}</a>
							</p>
						</div>
						<div class="version-item">
							<p class="version-msg">{{$t('android.version')}}: {{newAndroid.version}}</p>
							<!-- <p class="version-msg">{{$t('android.size')}}: 18.6M</p> -->
							<p class="version-msg">{{$t('android.updateTime')}}: {{newAndroid.createTime | formatDate}}</p>
							<p class="version-msg">{{$t('android.compatible')}}: Android</p>
						</div>
					</li>
					<!-- <li class="download-item">
						<div class="icon-img">
                            <img class="" src="../../assets/images/ai-img-qd.png" alt="" />
						</div>
						<div class="download-text">
							<h3>{{$t('patch.title')}}</h3>
							<p class="bit-link">
							    <a :href="newPatch.url" class="bit64">{{$t('patch.linkOne')}}</a><span>|</span> 
								<a :href="newPatch.url" class="bit32">{{$t('patch.linkTwo')}}</a>
							</p>
						</div>
						<div class="version-item">
							<p class="version-msg">{{$t('patch.version')}}: {{newPatch.version}}</p>
							<p class="version-msg">{{$t('patch.size')}}: 18.6M</p>
							<p class="version-msg">{{$t('patch.updateTime')}}: {{newPatch.create_date | formatDate}}</p>
							<p class="version-msg">{{$t('patch.compatible')}}: Windows7/8/10/11</p>
						</div>
					</li> -->
				</ul>
			</div>
			<div class="history-version" v-if="curHost == 'www'">
				<!-- <router-link to="/HistoryVersion" target="_blank">展开所有历史版本☟</router-link> -->
				<div>
					<a href="javascript:;" @click="showAllVersions = !showAllVersions">{{$t('history.title')}}☟</a>
				</div>
			    <!-- 各版本列表 -->
				<div class="version-table" v-show="showAllVersions">
					<!-- <div class="version-no">
						<p>{{$t('history.tip')}}</p>
					</div> -->
					<div class="version-list">
						<table class="version-data">
							<tr>
								<th class="data-title">{{$t('history.package')}}</th>
								<th class="data-title">{{$t('history.product')}}</th>
								<th class="data-title">{{$t('history.historyVersion')}}</th>
								<th class="data-title">{{$t('history.applicableSystem')}}</th>
								<th class="data-title">{{$t('history.time')}}</th>
								<th class="data-title">{{$t('history.download')}}</th>
							</tr>
							<tr v-for="window in windowVersions" :key="window.id">
								<td class="data-content">
									<!-- <img class="data-icon" src="../../assets/images/ai-img-w.png" alt="" /> -->
									{{window.title}}
									<div class="version-intro">
										<img class="info-img" src="../../assets/images/info-circle-filled.png" @mouseenter="showVersionDescribe(window.id)"  @mouseleave="hideVersionDescribe"/>
										<div class="detailed box-shadow" v-if="describeId ==  window.id">
											<h5 class="desc-title"><img src="../../assets/images/info-circle-filled-b.png" />版本说明</h5>
											<p v-html="window.contents"></p>
										</div>
									</div>
								</td>
								<td class="data-content">{{window.product}}</td>
								<td class="data-content">{{window.version}}</td>
								<td class="data-content"><img class="data-icon" src="../../assets/images/ai-img-w.png" alt="" /></td>
								<td class="data-content">{{window.createTime | formatDate}}</td>
								<td class="data-content">
									<img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
									<a :href="window.url" class="current-download">{{$t('history.download')}}</a>
								</td>
							</tr>
							<tr v-for="mac in macVersions" :key="mac.id">
								<td class="data-content">	
									{{mac.title}}
									<div class="version-intro">
										<img class="info-img" src="../../assets/images/info-circle-filled.png" @mouseenter="showVersionDescribe(mac.id)"  @mouseleave="hideVersionDescribe"/>
										<div class="detailed box-shadow" v-if="describeId ==  mac.id">
											<h5 class="desc-title"><img src="../../assets/images/info-circle-filled-b.png" />版本说明</h5>
											<p>
												{{mac.contents}}
											</p>
										</div>
									</div>
								</td>
								<td class="data-content">{{mac.version}}</td>
								<td class="data-content"><img class="data-icon" src="../../assets/images/ai-img-apple.png" alt="" /></td>
								<td class="data-content">{{mac.createTime | formatDate}}</td>
								<td class="data-content">
									<img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
									<a :href="mac.url" class="current-download">{{$t('history.download')}}</a>
								</td>
							</tr>
							<tr v-for="android in androidVersions" :key="android.id">
								<td class="data-content">
									<!-- <img class="data-icon" src="../../assets/images/ai-img-android.png" alt="" /> -->
									{{android.title}}
									<div class="version-intro">
										<img class="info-img" src="../../assets/images/info-circle-filled.png" @mouseenter="showVersionDescribe(android.id)"  @mouseleave="hideVersionDescribe"/>
										<div class="detailed box-shadow" v-if="describeId ==  android.id">
											<h5 class="desc-title"><img src="../../assets/images/info-circle-filled-b.png" />版本说明</h5>
											<p>
												{{android.contents}}
											</p>
										</div>
									</div>
								</td>
								<td class="data-content">{{android.version}}</td>
								<td class="data-content"><img class="data-icon" src="../../assets/images/ai-img-android.png" alt="" /></td>
								<td class="data-content">{{android.createTime | formatDate}}</td>
								<td class="data-content p-relative">
									<img class="data-download"  src="../../assets/images/ai-img-download.png" width="20" height="20" />
									<a :href="android.url" class="current-download">{{$t('history.download')}}</a>
									<img class="data-download" src="../../assets/images/ai-img-scan.png" width="20" height="20" @mouseenter="qrShow = true" @mouseleave="qrShow = false" />
									<vue-qr :logoSrc="logoIcon" class="qr-img box-shadow" v-if="qrShow" :text="android.url"></vue-qr>
								</td>
							</tr>
							<tr v-for="patch in qdVersions" :key="patch.id">
								<td class="data-content">{{patch.title}}</td>
								<td class="data-content">{{patch.version}}</td>
								<td class="data-content"><img class="data-icon" src="../../assets/images/ai-img-qd.png" alt="" /></td>
								<td class="data-content">{{patch.createTime | formatDate}}</td>
								<td class="data-content">
									<img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
									<a :href="patch.url" class="current-download">{{$t('history.download')}}</a>
								</td>
							</tr>
							<tr v-for="hty in historyVersions" :key="hty.id">
								<td class="data-content">
									<img class="data-icon" v-if="hty.version && hty.version.indexOf('Mouse') != -1" src="../../assets/images/ai-img-mouse1.png" alt="" />
									<img class="data-icon" v-if="hty.version && hty.version.indexOf('Pointer') != -1" src="../../assets/images/ai-img-pen.png" alt="" />
									<img class="data-icon" v-if="hty.version && hty.version.indexOf('Recorder') != -1" src="../../assets/images/ai-img-recorder.png" alt="" />
									<img class="data-icon" v-else-if="hty.version && hty.version.indexOf('Mouse') == -1 && hty.version.indexOf('Pointer') == -1 && hty.version.indexOf('Recorder') == -1" src="../../assets/logo/qingjing/ai-img-logo2.png" alt="" />
									{{hty.title}}
									<div class="version-intro">
										<img class="info-img" src="../../assets/images/info-circle-filled.png" @mouseenter="showVersionDescribe(hty.id)"  @mouseleave="hideVersionDescribe"/>
										<div class="detailed box-shadow" v-if="describeId ==  hty.id">
											<h5 class="desc-title"><img src="../../assets/images/info-circle-filled-b.png" />版本说明</h5>
											<p v-html="hty.contents"></p>
										</div>
									</div>
								</td>
								<td class="data-content">
									<p v-if="hty.products && hty.products[0] =='Mouse'">鼠标</p>
									<p v-if="hty.products && hty.products[0] =='Pointer'">翻页笔</p>
									<p v-if="hty.products && hty.products[0] =='Recorder'">录音笔</p>
								</td>
								<td class="data-content">{{hty.version}}</td>
								<td class="data-content">
									<p v-if="hty.platform == 'Mac'"><img class="data-icon" src="../../assets/images/ai-img-apple.png" alt="" /></p>
									<p v-if="hty.platform == 'Android'"><img class="data-icon" src="../../assets/images/ai-img-android.png" alt="" /></p>								
									<p v-if="hty.platform == 'Windows'"><img class="data-icon" src="../../assets/images/ai-img-w.png" alt="" /></p>								
								</td>
								<td class="data-content">{{hty.createTime | formatDate}}</td>
								<td class="data-content p-relative">
									<img class="data-download" src="../../assets/images/ai-img-download.png" width="20" height="20" />
									<a :href="hty.url" target="_blank" class="current-download">{{$t('history.download')}}</a>
									<img class="data-download" v-if="hty.platform == 'Android'" src="../../assets/images/ai-img-scan.png" width="20" height="20" @mouseenter="qrShow = hty.id" @mouseleave="qrShow = false" />
									<vue-qr :logoSrc="logoIcon"  class="qr-img box-shadow" v-if="qrShow == hty.id" :text="hty.url"></vue-qr>
								</td>
							</tr>
						</table>
					</div>
					
				</div>				
			</div>
		</div>
	</div>
</template>

<script>
    import axios from 'axios';
	import vueQr from 'vue-qr';
	import store from '@/store/index.js'

	export default {
		data(){
			return{
				curHost: '',
				bitFlag: 0,
				versionFlag: 0,
				showAllVersions: false,
				qrShow: false,
				logoIcon: require("../../assets/logo/qingjing/ai-img-logo2.png"),
				describeId: '',
				//鼠标滑过控制
				androidIcon: true,
				androidCode: false,
				//最新的版本
				newWindow: {},
				newWindowBeta: {},
				newMac: {},
				newAndroid: {},
				newPatch: {},
				//所有版本数组
				windowVersions: [],
				macVersions: [],
				androidVersions: [],
				qdVersions: [],
				historyVersions: [],
				windowBetaVersions: [],  //内测版本
				ipAddress: 'https://apiv2.qjzn.ltd/api',  //  生产：https://apiv2.qjzn.ltd/api， 开发：https://devapi.qjzn.ltd/api
			}
		},
		components: {
            vueQr
        },
		mounted(){  
			this.curHost = store.getters['getCurrentHost']
			//获取接口中心数据
            this.getWindowsVersion();
			this.getMacVersion();
			this.getAndroidVersion();
			//this.getPatchVersion();
			this.getHistoryVersion();
			this.getWindowsBetaVersion();
		},
		filters: {//配置过滤器
			formatDate: function (value) {//调用时间戳为日期显示
				let date = new Date(value)
				let y = date.getFullYear()  //获取年份
				let m = date.getMonth() + 1  //获取月份
				m = m < 10 ? "0" + m : m  //月份不满10天显示前加0
				let d = date.getDate()  //获取日期
				d = d < 10 ? "0" + d : d  //日期不满10天显示前加0
				return y + "-" + m + "-" + d
				//也可以获取更精准时间
				//let h = date.getHours(),                   //小时
				//let m = date.getMinutes(),                 //分
				//let s = date.getSeconds(),                 //秒
				//let ls = date.getMilliseconds()            //毫秒
			},
		},
		methods:{
			//根据不同域名取不同的APPID
			getAppId(){
				let id = ''
				if (this.curHost == 'www' || this.curHost == 'imouse' || this.curHost == 'yundesk'){
					id = 'AiOffice-Destop-H5'
				}else if (this.curHost == 'shadow'){
					id = 'AiOffice-ZhuoYao-Destop'
				}else if (this.curHost == 'aiodiy') {
					id = 'AiOffice-AioDiy-Destop'
				}
				else if (this.curHost == 'dido') {
					id = 'AiOffice-Sanag-Destop'
				}
				else if (this.curHost == 'thinkplus') {
					id = 'AiOffice-Thinkplus-Destop'
				} else {
					id = 'AiOffice-Destop-H5'
				}
				return id
			},
			//接口数据
			getWindowsVersion(){
				const appId = this.getAppId()
				// let params = {
				// 		appid: appId,
				// 		"platform": "Windows",
				// 		packageType: 'DesktopFull',
				// 	}
				axios.get(`${this.ipAddress}/api-product/versionManagement/mainAppId?appId=${appId}&platform=Windows&packageType=DesktopFull`,{
					headers: {
						"Content-Type": "application/json;charset=uft-8",
						"Access-control-allow-origin": "*"
					}
				}).then((res)=>{
					console.log(res)
					if(res.data.datas){
						this.newWindow = res.data.datas;
						//this.windowVersions = res.data.data;
					}
					
				})
			},
			getMacVersion(){
				const appId = this.getAppId()
				axios.get(`${this.ipAddress}/api-product/versionManagement/mainAppId?appId=${appId}&platform=MacOs&packageType=DesktopFull`,{
					headers: {
						"Content-Type": "application/json;charset=uft-8",
						"Access-control-allow-origin": "*"
					}
				}).then((res)=>{
					if(res.data.datas){
						this.newMac = res.data.datas;
						//this.macVersions = res.data.data;
					}
				})
			},
			getAndroidVersion(){
				const appId = 'AiOffice-App'

				axios.get(`${this.ipAddress}/api-product/versionManagement/mainAppId?appId=${appId}&platform=Android&packageType=App`,{
					headers: {
						"Content-Type": "application/json;charset=uft-8",
						"Access-control-allow-origin": "*"
					}
				}).then((res)=>{
					if(res.data.datas){
						this.newAndroid = res.data.datas
						//this.androidVersions = res.data.data;
					}
					
				})
			},
			getPatchVersion(){
				const  appId = 'AiOffice-Patch'
				axios.get(`${this.ipAddress}/api-product/versionManagement/page?appId=${appId}&platform=&packageType=DriverPatch&pageSize=100&pageNumber=1`,{
					headers: {
						"Content-Type": "application/json;charset=uft-8",
						"Access-control-allow-origin": "*"
					}
				}).then((res)=>{
					if(res.data.datas){
						this.qdVersions = res.data.datas.list;
					}

				})
			},
			getHistoryVersion(){
				const appId = 'AiOffice-History'
				axios.get(`${this.ipAddress}/api-product/versionManagement/page?appId=${appId}&platform=&packageType=&pageSize=100&pageNumber=1`,{
					headers: {
						"Content-Type": "application/json;charset=uft-8",
						"Access-control-allow-origin": "*"
					}
				}).then((res)=>{
					if(res.data.datas){
						this.historyVersions = res.data.datas.list;
					}
				})
			},
			//内测版本
			getWindowsBetaVersion(){
				let params = {
						action: "getAppVersionInfo",
						appid: "AiOffice-Destop-Beta",
						"platform": "Windows"
					}
				axios.post('https://upgradeapi.qjzn.ltd/getUpdateInfo', JSON.stringify(params),{
					headers: {
						"Content-Type": "application/json;charset=uft-8",
						"Access-control-allow-origin": "*"
					}
				}).then((res)=>{
					if(res.data.data && res.data.data.length > 0){
						this.newWindowBeta = res.data.data.shift();
						this.windowBetaVersions = res.data.data;
					}
					
				})
			},
			//鼠标滑过显示二维码
			overAndroidCode(){
				this.androidIcon = false;
				this.androidCode = true;
			},
			outAndroidCode(){
				this.androidIcon = true;
				this.androidCode = false;
			},
			checkHistoryVersion(index){
				this.versionFlag = index;
			},
			//版本说明介绍
			showVersionDescribe(id){
				this.describeId = id;
			},
			hideVersionDescribe(){
				this.describeId = '';
			},
			showQrCode(){

			}
		}
	}
</script>

<style>
	.img-over,
	.img-out {
		opacity: 0;
		transition: all 4s;
	}
	
	.img-over,
	.img-out {
		opacity: 1;
		transition: all 4s;
	}
</style>
