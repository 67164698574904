//创建文件 I18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

//引入各个语言配置文件
import zh from '../VueI18n/language-zh';
import en from '../VueI18n/language-en';

//获取浏览器语言
const localLang = navigator.language || navigator.userLanguage;
console.log(localLang);
let lang = 'zh';
if(localLang == 'zh-CN'){
    lang = 'zh'
}else{
    lang = 'en'
}
//创建 Vue-i18n 实例 i18n
const i18n = new VueI18n({
    //设置默认语言
    locale: lang, //语言标识
    //添加多语言
    messages: {
        zh,
        en,
    }
})

//暴露 i18n
export default i18n;