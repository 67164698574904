<template>
	<div class="swiper">
		<swiper :options="swiperOption" class="swiper-container" ref="bannerSwiper" @someSwiperEvent="callback">
			<swiper-slide>
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">智能办公 AI Office</h3>
								<p class="banner-intro"><span class="color-o font-w600">让工作更轻松高效 </span></p>
								<p class="brief-explain m-top">智慧办公————以人工智能技术为基石，<br>
									通过软硬件结合产品落地到办公场景，打造新一代<br>
									“简单、高效、友好”办公场景，提升人类工作效率和幸福感
								</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-office.png" />
							</div>
					    </div>
					</div>	
				</a>
			</swiper-slide>
			<swiper-slide v-if="currentHost == 'www' || currentHost == 'yundesk'">
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">智能语音鼠标-<span class="color-o">V8</span></h3>
								<p class="banner-intro">一分钟可打<span class="color-o font-w600">400字</span></p>
								<p class="brief-explain m-top">语音打字 | 100+全球语言 | 24种方言</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-mouse.png" />
							</div>
					    </div>
					</div>	
				</a>
			</swiper-slide>
			<swiper-slide v-if="currentHost == 'www' || currentHost == 'yundesk'">
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">智能翻页演讲笔<span class="color-o"></span></h3>
								<p class="banner-intro"><span class="color-o font-w600">实时</span>录音、翻译字幕</p>
								<p class="brief-explain m-top">语音打字 | 100+全球语言 | 24种方言</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-fyb.png" />
							</div>
					    </div>
					</div>
				</a>
			</swiper-slide>
			<swiper-slide v-if="currentHost == 'www' || currentHost == 'yundesk'">
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">智能语音键盘<span class="color-o"></span></h3>
								<p class="banner-intro">能<span class="color-o font-w600">“说话打字”</span>操控的键盘</p>
								<p class="brief-explain m-top">语音打字 | 智能搜索 | 翻译打字 | 智能M键</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-keyboard.png" />
							</div>
					    </div>
					</div>
				</a>
			</swiper-slide>
			<swiper-slide v-if="currentHost == 'www' || currentHost == 'yundesk'">
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">录音翻译会议耳机-<span class="color-o">A03</span></h3>
								<p class="banner-intro"><span class="color-o font-w600">电话录音 会议APP录音</span></p>
								<p class="brief-explain m-top">通话翻译 | 通话录音 | 来电报姓名</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-ej.png" />
							</div>
					    </div>
					</div>
				</a>
			</swiper-slide>
			<swiper-slide v-if="currentHost == 'www' || currentHost == 'yundesk'">
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">智能录音笔-<span class="color-o">R3/R5</span></h3>
								<p class="banner-intro">边<span class="color-o font-w600">写</span>边<span class="color-o font-w600">录</span> 出口成章</p>
								<p class="brief-explain m-top">高清录音 | 语音速记 | 一键分享</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-record.png" />
							</div>
					    </div>
					</div>
				</a>
			</swiper-slide>
			<swiper-slide v-if="currentHost == 'www' || currentHost == 'yundesk'">
				<a href="javascript:;">
					<div class="banner-bg">
						<div class="banner-content">
							<div class="banner-text">
								<h3 class="banner-title">智能全向麦<span class="color-o"></span></h3>
								<p class="banner-intro">会议室<span class="color-o font-w600">“伴侣”</span></p>
								<p class="brief-explain m-top">会议纪要 | 纪要分享 | 通话录音 | 通话翻译</p>
								<div>
									<!-- <button class="more-btn font-w600">了解更多</button> -->
								</div>
							</div>
							<div class="banner-product">
								<img src="../../assets/images/ai-img-qxm.png" />
							</div>
					    </div>
					</div>
				</a>
			</swiper-slide>
			
		</swiper>
		<div class="swiper-pagination">
			<!-- <div class="page"></div> -->
		</div>    <!-- 分页 -->		
	</div>
</template>

<script>
	
    import Swiper from 'swiper'
	import store from '@/store/index.js'
	export default{
		name: 'carrousel',
		data() {
			return {
				swiperOption: {
					loop: true,
					speed: 1000,
					autoplay: {
						delay: 8000,
						disableOnInteraction: false
					},
					effect: 'slide',
					sliderPerView: 6,
					pagination: {
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
						dynamicBullets: false,
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
						hideOnClick: true,
					}
					
				},
				currentHost: '',
			}
		},
		computed: {
			swiper(){
				return this.$refs.bannerSwiper.$swiper    //这里需要加上$  $swiper
			}
		},
		created() {
			const name = store.getters['getCurrentHost']
			this.currentHost = name
		},
		mounted(){
			console.log('current', this.swiper);
			new Swiper ('.swiper-container', {
				loop: true,
				speed: 1000,
				autoplay: {
					delay: 8000,
					disableOnInteraction: true
				},
				// 如果需要分页器
				pagination: '.swiper-pagination',
				// 如果需要前进后退按钮
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				// 如果需要滚动条
				scrollbar: '.swiper-scrollbar',
			})
			//this.$swiper.slideTo(1,3000,true)
		},
		methods:{
			callback(){
	
			}
		}
	}
</script>

<style>
	.swiper{
		--swiper-theme-color: #ff6600;
		--swiper-pagination-color: #FF6A13;/* 两种都可以 */
		--swiper-navigation-size: 20px;
	}
	.swiper-button-prev, .swiper-button-next{
		color: #FF6A13 !important;
		background: rgba(255, 255, 255, 0.2);
		padding: 8px 5px;
		border-radius: 10px ;
	}
	.swiper-pagination{
        left: 50%;
		transform: translateX(-50%);
		bottom: 10px;
	}
	.swiper-pagination-bullet{
		margin: 0 5px;
		width: 14px !important;
		height: 14px !important;
	}
</style>
