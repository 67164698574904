<template>
	<div>
		<!-- <Header></Header> -->
		<div class="tipContent" v-if="isTip">您当前使用的浏览器版本过低，可能会出现界面显示异常或功能无法正常使用等问题，建议您更换为更高版本的浏览器</div>
		<div class="header" id="header" :class="{'header-bg': isScrolled}">
			<div class="head-content" :class="{'head-content-bg': isScrolled}">
				<div class="ai-logo">
					<a href="">
						<img v-if="curHost == 'www' || curHost == ''" src="../assets/logo/qingjing/ai-img-logo1.png" alt="">
						<img v-if="curHost == 'shadow'" src="../assets/logo/zhuoyao/logo.png" alt="">
						<img v-if="curHost == 'aiodiy'" src="../assets/logo/aiodiy/logo.png" alt="">
						<img v-if="curHost == 'imouse'" src="../assets/logo/cleverAI/logo.png" alt="">
						<img v-if="curHost == 'dido'" src="../assets/logo/dido/logo.png" alt="">
						<img v-if="curHost == 'thinkplus'" src="../assets/logo/thinkplus/logo.png" alt="">
						<img v-if="curHost == 'yundesk'" src="../assets/logo/yundesk/logo-w.png" alt="">
					</a>
				</div>
				<div class="head-nav">
					<!-- <ul>
						<li class="nav-text"><router-link to="/">首页</router-link></li>
						<li class="nav-text" @mouseenter="enter(1)" @mouseleave="leave(1)">
							<a href="javascript:;">解决方案</a>
							<div class="secone-nav box-shadow" v-show="seenSolution" style="width: 180px;">
								<p><router-link to="/GasSolution">智慧燃气解决方案</router-link></p>

							</div>
						</li>
						<li class="nav-text" @mouseenter="enter(2)" @mouseleave="leave(2)">
							<a href="javascript:;">支持与服务</a>
							<div class="secone-nav box-shadow" v-show="seenService">
								<p><router-link to="/Service">支持与服务</router-link></p>
							</div>
						</li>
						<li class="nav-text" @mouseenter="enter(3)" @mouseleave="leave(3)">
							<a href="javascript:;">关于我们</a>
							<div class="secone-nav box-shadow" v-show="seenAbout">
								<p><router-link to="/About">关于我们</router-link></p>
							</div>
						</li>
					</ul> -->
				</div>
				<div class="language-change" @mouseenter="languageEnter" @mouseleave="languageLeave">
					<a class="lang-title" href="javascript:;">{{$t('lang')}}</a>
					<div class="language-level" v-show="showLanguage">
						<ul v-for="(lang,index) in $t('languages')" :key="index">
							<li><a class="lang" href="javascript:;" @click="changeLanguage(lang.value)">{{lang.name}}</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<router-view></router-view>
		<div class="foot">
			<div class="foot-content">
				<div class="ai-logo-foot">
					<img v-if="curHost == 'www'" src="../assets/logo/qingjing/ai-img-logo1.png" alt="">
					<img v-if="curHost == 'shadow'" src="../assets/logo/zhuoyao/logo-w.png" alt="">
					<img v-if="curHost == 'aiodiy'" src="../assets/logo/aiodiy/logo-w.png" alt="">
					<img v-if="curHost == 'imouse'" src="../assets/logo/cleverAI/logo-w.png" alt="">
					<img v-if="curHost == 'dido'" src="../assets/logo/dido/logo-w.png" alt="">
					<img v-if="curHost == 'thinkplus'" src="../assets/logo/thinkplus/logo-w.png" alt="">
					<img v-if="curHost == 'yundesk'" src="../assets/logo/yundesk/logo-w.png" alt="">
				</div>
				<div class="foot-nav">
					<div class="foot-nav-left">
                        
                        <ul class="foot-nav-ul">
                            <li class="contact-way" v-if="curHost == 'www'">
                                <h5>{{$t('contact.link')}}</h5>
                                <p><a href="https://www.aioffice.ltd/" target="_blank">{{$t('contact.oldAddress')}}</a></p>
                            </li>
							<!-- <li class="contact-douyin">
								<span class="douyin">{{$t('contact.douyin')}}</span>
								<div class="douyin-img">
									<img src="../assets/images/ai-img-douyin.png" alt="" />
								</div>
							</li> -->
                        </ul>
					</div>
					<div class="foot-nav-right">
						<div class="work-time worktime">
							<h5>{{$t('contact.worktime')}}</h5>
							<p>{{$t('contact.timeFrame')}}</p>
						</div>						
						<ul class="foot-nav-qrcode">
							<li class="qrcode" v-if="['www', 'imouse', 'yundesk'].includes(curHost)">
								<div class="qrcode-img">
									<img v-if="curHost == 'www' || curHost == 'yundesk'" src="../assets/images/ai-img-douyin-bg.png" alt="" />
									<img v-if="curHost == 'imouse'" src="../assets/logo/cleverAI/img-douyin.png" alt="" />
								</div>
								<p>{{$t('contact.douyin')}}</p>
							</li>
							<li class="qrcode" v-if="['www','dido', 'imouse', 'yundesk'].includes(curHost)">
								<div class="qrcode-img">
									<img v-if="curHost == 'www' || curHost == 'yundesk'" src="../assets/images/ai-img-qcode.png" alt="" />
									<img v-if="curHost == 'imouse'" src="../assets/logo/cleverAI/img-qcode.png" alt="" />
									<img v-if="curHost == 'dido'" src="../assets/logo/dido/img-qcode.png" alt="" />
								</div>
								<p>{{$t('contact.weChatAccount')}}</p>
							</li>
							<li class="qrcode" v-if="['www', 'imouse', 'yundesk'].includes(curHost)">
								<div class="qrcode-img">
									<img v-if="curHost == 'www' || curHost == 'yundesk'" src="../assets/images/ai-img-service.png" alt="" />
									<img v-if="curHost == 'imouse'" src="../assets/logo/cleverAI/img-service.png" alt="" />
								</div>
								<p>{{$t('contact.customerService')}}</p>
							</li>
						</ul>
					</div>
				</div>
				<p class="record-no"><a href="https://beian.miit.gov.cn/">Copyright@ 粤ICP备2022076506号</a></p>
			</div>
		</div>
	</div>
</template>

<script>
	//import Header from './content/Header.vue'
    import store from '@/store/index.js';

	export default {
		components: {
			//Header,
		},
		data(){
			return{
				scroll: '',
				isScrolled: false,
				seenSolution: false,
				seenService: false,
				seenAbout: false,
				lang: '',
				showLanguage: false,
				isTip: false,   //浏览器版本判断
				curHost: '',
			}
		},
		created(){
			//获取浏览器信息，并判断版本
			let DEFAULT_VERSION = 9.0;
			let ua = navigator.userAgent.toLowerCase();
			let isIE = ua.indexOf("msie") > -1;
			let version = '';
			console.log(isIE + '0000')
			if(isIE){
				version = ua.match(/msie([\d.]+)/)[1];
				if(version <= DEFAULT_VERSION){
					this.isTip = true;
				}
			}
			//获取链接
			this.getPageUrl()
		},
		mounted() {
			document.title = 'AI-Office';  // 浏览器标签title
			//监听鼠标滚动事件
			window.addEventListener('scroll', this.debounce(this.handleScroll,30), true)||window.addEventListener("DOMMouseScroll",this.debounce(this.handleScroll,30),false)
		},
		methods:{
			//函数防抖
			debounce(func, wait) {
				let timeout;
				return function () {
					let context = this;
					let args = arguments;
					if (timeout) clearTimeout(timeout);
					timeout = setTimeout(() => {
						func.apply(context, args)
					}, wait);
				}
			},
			//获取页面链接
			getPageUrl(){
				let url = window.location.href
				console.log(url)
				const urlObject = new URL(url)
				//const urlObject = new URL('https://www.aioffice.ltd/')
				
				if(urlObject.hostname != ''){
					const hostName = urlObject.hostname.split('.')[0]
					if (hostName == 'aioffice') {
						this.curHost = 'www'
					} else if(hostName == 'n68') {
						this.curHost = 'dido'
					} else {
						this.curHost = hostName
					}
					store.commit('setCurrentHost', this.curHost)
				}
			},
			//判断滚动方向
			handleScroll(){
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
				if(this.scroll > 0){
					//document.querySelector('#header').style.backgroundImage = 'rgba(0,0,0,0.2)';
					this.isScrolled = true;
				}else{
					//document.querySelector('#header').style.backgroundColor = 'transparent';
					this.isScrolled = false;
				}
			},
			enter(index){
				if(index == 1)
					this.seenSolution = true;
				else if(index == 2)
					this.seenService = true;
				else if(index == 3)
					this.seenAbout = true;
			},
			leave(index){
				if(index == 1)
					this.seenSolution = false;
				else if(index == 2)
					this.seenService = false;
				else if(index == 3)
					this.seenAbout = false;
			},
			//语言界面
			languageEnter(){
                this.showLanguage = true
			},
			languageLeave(){
				this.showLanguage = false
			},
			changeLanguage(lang){
				//console.log(lang)
				if(this.$i18n.locale != lang){
                    this.$root.$i18n.locale = lang;
					store.commit('setCurrentLanguage', lang)
				}
			}
		},	
	}
</script>

<style lang="scss">
</style>
