<!-- 页面暂未使用 便于测试先保留-->
<template>
    <div class="">
        <Banner></Banner>
        <div class="">
            <h3>历史版本</h3>
        </div>
    </div>
</template>

<script>
    import Banner from '../content/Banner.vue';
    import axios from 'axios';

    export default{
        components: {
            Banner
        },
        data(){
            return{
                versionList: []
            }
        },
        created(){
            //组件创建后去请求接口获取版本列表
          this.initializeVersion();
        },
        methods: {
            initializeVersion(){
                console.log(110);
                let params = {
                    action: "getAppVersionInfo",
                    appid: "AiOffice-Destop",
                }
                axios.post('http://upgradeapi.qjzn.ltd/getUpdateInfo', JSON.stringify(params),{
                    headers: {
                        "Content-Type": "application/json;charset=uft-8"
                    }
                }).then((res)=>{
                    console.log(res)
                })
                console.log(this.versionList);
            }
        }
    }
</script>