<template>
	<div>
		<Banner v-if="curLanguage == 'zh'"></Banner>
		<BannerLanguage v-if="curLanguage != 'zh'"></BannerLanguage>
		<Download></Download>
	</div>
</template>

<script>
	import Banner from './Banner.vue';
	import BannerLanguage from './BannerLanguage.vue';
    import Download from '../download/Download.vue';
	import store from '@/store/index.js';

	export default{
		name: 'carrousel',
		components:{
			Banner,
			Download,
			BannerLanguage
		},
		data() {
			return {
				curHost: '',
			}
		},
		computed:{ 
			curLanguage(){
				let language = '';
				let storeLanguage = store.getters['getCurrentLanguage'];
				if(storeLanguage != ''){
					language = storeLanguage
				}else{
					language = this.$i18n.locale
				}
				return language;
			}			
		},
		mounted() {
			this.curHost = store.getters['getCurrentHost']
		},
		methods:{
			callback(){
				console.log(1);
			},
			enterCaseBlock(index){
				if(index == 0)
					this.activeClass = 0
				else if(index == 1)
					this.activeClass = 1
				else if(index == 2)
					this.activeClass = 2
			},
			enterTechData(index){
				if(index == 0){
					this.activeTech = 0;
					this.techOne = 0;
				}else if(index == 1){
					this.activeTech = 1;
					this.techOne = 1;
				}else if(index == 2){
					this.activeTech = 2;
					this.techOne = 2;
				}
			},
		}
	}
</script>

<style lang="scss">
	
	
</style>
